@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.main-content {
  margin-top: 75px;
}

/* Container setup for Flexbox */
.container {
  display: flex;
  flex-wrap: wrap; /* Allows columns to wrap on smaller screens */
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: #7a6a5d;
}

img {
  width: 100%; /* Scale image to fit the grid column width */
  max-width: 100%; /* Prevent the image from growing larger than its container */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Crop the image if necessary to fill the space */
  border-radius: 8px; /* Optional: Rounded corners for styling */
}

.brown-bg {
  background-color: #edd6c4;
}

.blue-bg {
  background-color: #d1ecef;
}

.text-center {
  text-align: center;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 20px;
}

.padding-bottom {
  padding-bottom: 50px;
}
.padding-top {
  padding-top: 50px;
}

.space-between {
  justify-content: space-between;
}

h1 {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  margin-top: 0px;
  margin-bottom: 10px;
  width: 100%;
  line-height: 1;
}

h2 {
  font-family: 'DM Serif Display', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

h3 {
  margin-top: 0px;
  margin-bottom: -10px;
}

h4 {
  margin-top: 0px;
  margin-bottom: -10px;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}
ul {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}

.errorMessage {
  color: red;
}

.lightBrownFontColour {
  color: #edd6c4;
}
.mediumBrownFontColour {
  color: #e0bb9e;
}

.border-bottom {
  border-bottom: 1px solid #7a6a5d;
}

.img-responsive {
  width: 100%; /* Scale image to fit the grid column width */
  max-width: 100%; /* Prevent the image from growing larger than its container */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Crop the image if necessary to fill the space */
  border-radius: 8px; /* Optional: Rounded corners for styling */
}

input {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #7a6a5d;
}

input::placeholder {
  color: #edd6c4; /* Change to your desired color */
  opacity: 1; /* Adjust opacity if necessary (default is 0.5) */
}

.loginScreenValueEntryBoxTitles {
  text-align: left;
  width: 100%;
  font-weight: 600;
}

.login-page {
  background-color: #d1ecef; /* Background color for the entire login page */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.loginBoundingRectangle {
  background-color: white;
  border: 1px solid white;
  border-radius: 38px;
  padding: 60px;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center;
  max-width: 500px;
  margin: 20px;
}

form {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.blueButton {
  background-color: #35cbdd;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 650;
  font-style: normal;
  text-transform: uppercase;
  border: 2px solid #35cbdd;
  padding: 80px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.blueButton:hover {
  background-color: white;
  color: #35cbdd;
  border: 2px solid #35cbdd;
  cursor: pointer;
}

.column-wrapper {
  padding: 0 10px; /* Controls the spacing between columns */
}

/* Columns will be full width on small screens by default */
[class*='col-'] {
  flex: 0 0 100%;
  max-width: 100%;
}

.col-4-sm {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.col-8-sm {
  flex: 0 0 63.66%;
  max-width: 63.66%;
  margin-left: 3%;
}

.col-2-sm {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

/* Medium screens (768px and up): 12-column layout */
@media (min-width: 768px) {
  .col-1-md {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-2-md {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col-3-md {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4-md {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-5-md {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col-6-md {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7-md {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-8-md {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-9-md {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10-md {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-11-md {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-12-md {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Large screens (1024px and up): 12-column layout */
@media (min-width: 1024px) {
  h1 {
    font-size: 5rem;
  }
  h2 {
    font-size: 3rem;
  }
  .col-left {
    order: 2; /* Show this column on the right by default */
    flex: 0 0 50%; /* Adjusts width to fit half of the container */
    max-width: 50%;
  }
  .col-right {
    order: 1; /* Show this column on the left */
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-1-lg {
    flex: 0 0 8.33%;
    max-width: 8.33%;
  }
  .col-2-lg {
    flex: 0 0 16.66%;
    max-width: 16.66%;
  }
  .col-3-lg {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-4-lg {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .col-5-lg {
    flex: 0 0 41.66%;
    max-width: 41.66%;
  }
  .col-6-lg {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-7-lg {
    flex: 0 0 58.33%;
    max-width: 58.33%;
  }
  .col-8-lg {
    flex: 0 0 66.66%;
    max-width: 66.66%;
  }
  .col-9-lg {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-10-lg {
    flex: 0 0 83.33%;
    max-width: 83.33%;
  }
  .col-11-lg {
    flex: 0 0 91.66%;
    max-width: 91.66%;
  }
  .col-12-lg {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
